import React, { useContext } from "react"
import ResponsiveContext from "../../contexts/responsive-context"
import useIsClient from "../is-client-hook"
import TileAncestor from "../layout/tile-ancestor"
import TileParent from "../layout/tile-parent"
import TileChild from "../layout/tile-child"
import H2 from "../unitary/h2"
import TableRow, { FIRST_COLUMN_STYLES } from "../table/table-row"

function CustomHeaderRow(props) {
  const context = useContext(ResponsiveContext)
  if (context.isMobile) {
    return null
  }

  const headers = props.data
  return headers.map((header, index) => {
    return (
      <TileChild
        key={index}
        classes={["has-text-centered", `is-${header.width}`]}
      >
        <H2 title={header.title} />
      </TileChild>
    )
  })
}

export default function TableSimple(props) {
  const isClient = useIsClient().isClient

  if (!isClient) {
    return null
  }

  const tableRows = props.data.rows.map((row, index) => {
    return (
      <TileAncestor key={index} classes={["mb-5"]}>
        <TileParent>
          <TableRow
            headers={props.data.headers}
            row={row}
            firstColumnStyle={FIRST_COLUMN_STYLES.header}
            isVerticallyAligned={props.isVerticallyAligned}
            bodyHorizontalAlignment={props.bodyHorizontalAlignment}
          />
        </TileParent>
      </TileAncestor>
    )
  })

  return (
    <div>
      <TileAncestor>
        <TileParent>
          <CustomHeaderRow data={props.data.headers} />
        </TileParent>
      </TileAncestor>
      {tableRows}
    </div>
  )
}
