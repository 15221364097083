// @ts-ignore
import yaml from "../../data/brand-advertising-on-youtube.yaml"
import React from "react"
import { validatePageYamlData } from "../../helpers/prop-types"
import Header from "../../components/sections/header"
import LayoutContainer from "../../components/layout/layout-container"
import Toothpaste from "../../components/sections/toothpaste"
import ResponsiveTitlePlain from "../../components/unitary/responsive-title-plain"
import Content from "../../components/unitary/content"
import TableSimple from "../../components/sections/table-simple"
import { SectionBox } from "../../components/sections/section-box"
import TitleWithUnderline from "../../components/unitary/title-with-underline"
import { TABLE_ROW_BODY_HORIZONTAL_ALIGNMENTS } from "../../components/table/table-row"
import { PORTRAIT_BODY_TEXT_ALIGNMNENTS } from "../../components/child-mappable/portrait"
import { graphql } from "gatsby"
import TileParentChildBox from "../../components/layout/tile-parent-child-box"
import { SectionTileAncestor } from "../../components/sections/section-tile-ancestor"
import { PortraitArrayWithTitle } from "../../components/sections/portrait-array-with-title"

class BrandAdvertisingOnYouTube extends React.Component {
  render() {
    validatePageYamlData(yaml)
    const images = this.props.data

    const introductionToothpasteTitleChild = (
      <ResponsiveTitlePlain title={yaml.introduction.title} />
    )

    const notesToothpasteTitleChild = (
      <ResponsiveTitlePlain title={yaml.notes.title} />
    )
    return (
      <LayoutContainer>
        <Header {...yaml.header} />
        <Toothpaste
          titleChild={introductionToothpasteTitleChild}
          chevronColumnWidth={"is-3"}
        >
          <Content body={yaml.introduction.markdown} />
        </Toothpaste>
        <SectionBox>
          <TitleWithUnderline title={yaml.three_formats.title} />
          <TableSimple
            data={yaml.three_formats.table}
            isVerticallyAligned={true}
            bodyHorizontalAlignment={
              TABLE_ROW_BODY_HORIZONTAL_ALIGNMENTS.centered
            }
          />
        </SectionBox>
        <PortraitArrayWithTitle
          data={yaml.identify_your_audience}
          images={images}
          options={{
            portraitBodyTextAlignment: PORTRAIT_BODY_TEXT_ALIGNMNENTS.left,
          }}
        />
        <SectionTileAncestor>
          <TileParentChildBox>
            <TitleWithUnderline title={yaml.frequency_capping.title} />
            <Content body={yaml.frequency_capping.body} />
          </TileParentChildBox>
          <TileParentChildBox>
            <TitleWithUnderline title={yaml.how_to_guides.title} />
            <Content body={yaml.how_to_guides.body} />
          </TileParentChildBox>
        </SectionTileAncestor>
        <Toothpaste
          titleChild={notesToothpasteTitleChild}
          chevronColumnWidth={"is-2"}
        >
          <div id="notes">
            <Content body={yaml.notes.markdown} />
          </div>
        </Toothpaste>
      </LayoutContainer>
    )
  }
}

export default BrandAdvertisingOnYouTube

export const query = graphql`
  query {
    audience_venn_diagram: file(
      relativePath: {
        eq: "brand-advertising-on-youtube/audience_venn_diagram.png"
      }
    ) {
      ...unsizedFluidImage
    }
  }
`
